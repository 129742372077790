import { SupabaseClient } from '@supabase/supabase-js';
import { DateTime } from 'luxon';
import { dissoc } from 'ramda';

import { Languages } from '@/app/i18n/settings';
import { Database } from '@/types/database.types';

// TODO: move these
export type OrganizationRPCResult = {
  organization_id: string | null;
  title: string | null;
  parent_organization_title: string;
  score: number | null;
  confidence: number | null;
  address: string;
  postal_code: string;
  task_count: number;
  task_status: string;
  energy_labels: string;
};

export type GetOrganizationsArgs = {
  supabase: SupabaseClient<Database>;
  customer_id: string;
  start: number;
  pageSize: number;
  orderBy?: keyof OrganizationRPCResult;
  ascending?: boolean;
  searchText?: string;
  selected_parent_organization_id_list?: string[];
};

export const getSingleProperty = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, organization(*), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->>${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, building_type!inner(*, name: name->>${lng}), addresses: address(*), task(*))`
    )
    .eq('id', id)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO())
    .single();
  return property;
};

export const getOrganizationById = async (supabase: SupabaseClient<Database>, id: string) => {
  const organization = await supabase.from('organization').select('*, property(id), task(*, )').eq('id', id).single();
  return organization;
};

export const getPropertiesByIds = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const property = await supabase
    .from('property')
    .select(
      `*, customer_id, organization(*, parentOrganization: parent_organization_id(title)), part_lvl_2_prediction_condition(*, part_lvl_2: building_element_level_2(*, name: name->>${lng}, description: description->${lng}, checklist: checklist->${lng})), buildings: building(*, building_type!inner(*, name: name->>${lng}), addresses: address(*), task(*)), units: unit(*)`
    )
    .or(`id.eq.${id},organization_id.eq.${id}`)
    .eq('part_lvl_2_prediction_condition.is_stale', false)
    .gte('part_lvl_2_prediction_condition.created_at', DateTime.now().minus({ days: 7 }).toISO());
  return property;
};

export const getTasksByOrganizationId = async (supabase: SupabaseClient<Database>, id: string, lng: Languages) => {
  const { data } = await supabase
    .from('task')
    .select(
      `*, constructive_activity(*), preventive_activity(*), remedial_activity(*), improvement_activity(*), buildings: building(*), part_lvl_2_task(*, part_lvl_2: building_element_level_2(*, name: name->${lng}, description: description->${lng}, checklist: checklist->${lng}, part_lvl_1: building_element_level_1(*, name: name->${lng})), part_lvl_2_condition(*, buildings: building(*), images: condition_img(*), score(*, short_text: short_text->${lng}, long_text: long_text->${lng})))`
    )
    .eq('organization_id', id);
  return data;
};

export const getOrganizationName = async (supabase: SupabaseClient<Database>, id: string) => {
  return await supabase.from('organization').select('name: title').eq('id', id).single();
};

export const getOrganizationsListRPC = async ({
  supabase,
  start,
  pageSize,
  searchText = '',
  ascending,
  orderBy,
  customer_id,
  selected_parent_organization_id_list,
}: GetOrganizationsArgs) => {
  // TODO test sql injextion

  const { data, error } = await supabase.rpc('get_organization_list', {
    v_start: start,
    v_page_size: pageSize,
    v_search_text: `%${searchText}%`,
    v_ascending: ascending || false,
    v_order_by: orderBy ?? 'title',
    v_customer_id: customer_id,
    v_selected_parent_organization_id_list: selected_parent_organization_id_list,
  });

  if (error) {
    return {
      data: null,
      error: error,
      count: 0,
    };
  }

  if (!data) {
    return {
      data: null,
      error: new Error('no data'),
      count: 0,
    };
  }

  const count = data[0]?.count;

  return {
    data: data.map((org) => dissoc('count', org)),
    error: null,
    count,
  };
};

export const getOrganizationListWithSupabase = async ({
  supabase,
  start,
  pageSize,
  searchText = '',
  ascending = true,
  orderBy = 'title',
  customer_id,
  selected_parent_organization_id_list,
}: GetOrganizationsArgs) => {
  try {
    // First get weighted scores for all properties
    const propertyQuery = supabase
      .from('property')
      .select(
        `
      organization_id,
      customer_id,
      building (
        m2,
        bfe_number,
        energy_label,
        address (
          road_name,
          number,
          postal_code
        )
      ),
      prediction_condition_avg,
      prediction_confidence_score_avg
    `
      )
      .eq('customer_id', customer_id);

    const { data: properties, error: propertiesError } = await propertyQuery;

    if (propertiesError) throw propertiesError;

    // Get the list of organization IDs that have properties
    // If there's a search term, only include organizations that match the search criteria
    const validOrgIds = Object.keys(
      properties.reduce((acc, p) => {
        if (!p.organization_id) return acc;

        // If there's no search text, include all organizations
        if (!searchText) {
          acc[p.organization_id] = true;
          return acc;
        }

        // Check if any building or its address matches the search criteria
        const hasMatch = p.building?.some((building) => {
          const searchLower = searchText.toLowerCase();

          // Check bfe_number
          if (building.bfe_number?.toLowerCase().includes(searchLower)) {
            return true;
          }

          // Check addresses
          return building.address?.some((addr) => {
            const fullAddress = `${addr.road_name || ''} ${addr.number || ''}`.trim();
            return (
              fullAddress.toLowerCase().includes(searchLower) || addr.postal_code?.toLowerCase().includes(searchLower)
            );
          });
        });

        if (hasMatch) {
          acc[p.organization_id] = true;
        }
        return acc;
      }, {} as Record<string, boolean>)
    );

    // Get organizations with their parent info
    let query = supabase
      .from('organization')
      .select(
        `
        id,
        title,
        parent_organization_id,
        parent: parent_organization_id(id,title),
        task(id, "isDone")
      `
      )
      .eq('customer_id', customer_id)
      .in('id', validOrgIds); // Only include organizations that have properties

    // Add parent organization filter if provided
    if (selected_parent_organization_id_list?.length) {
      query = query.in('parent.id', selected_parent_organization_id_list);
    }

    // Add organization title search filter
    if (searchText) {
      query = query.or('title.ilike.%' + searchText + '%');
    }

    const { data: organizations, error: orgsError } = await query;
    const count = organizations?.length;

    if (orgsError) throw orgsError;

    // Calculate weighted scores per organization
    const orgScores = properties.reduce(
      (acc, property) => {
        const orgId = property.organization_id;
        if (!acc[orgId]) {
          acc[orgId] = {
            total_weighted_condition_score: 0,
            total_weighted_confidence_score: 0,
            total_building_size: 0,
            bfe_numbers: new Set<string>(),
            energy_labels: new Set<string>(),
            addresses: new Set<string>(),
            postal_codes: new Set<string>(),
          };
        }

        // Calculate weighted scores using building size
        if (property.building && property.building.length > 0) {
          property.building.forEach((building) => {
            const size = building.m2 ? parseFloat(building.m2) : 1;
            acc[orgId].total_weighted_condition_score += (property.prediction_condition_avg || 0) * size;
            acc[orgId].total_weighted_confidence_score += (property.prediction_confidence_score_avg || 0) * size;
            acc[orgId].total_building_size += size;

            if (building.bfe_number) acc[orgId].bfe_numbers.add(building.bfe_number);
            if (building.energy_label) acc[orgId].energy_labels.add(building.energy_label);

            building.address?.forEach((addr) => {
              if (addr.road_name && addr.number) {
                acc[orgId].addresses.add(`${addr.road_name} ${addr.number}`);
              }
              if (addr.postal_code) acc[orgId].postal_codes.add(addr.postal_code);
            });
          });
        } else {
          // For properties without buildings, use default values
          acc[orgId].total_weighted_condition_score += property.prediction_condition_avg || 0;
          acc[orgId].total_weighted_confidence_score += property.prediction_confidence_score_avg || 0;
          acc[orgId].total_building_size += 1; // Use 1 as default weight
        }

        return acc;
      },
      {} as Record<
        string,
        {
          total_weighted_condition_score: number;
          total_weighted_confidence_score: number;
          total_building_size: number;
          bfe_numbers: Set<string>;
          energy_labels: Set<string>;
          addresses: Set<string>;
          postal_codes: Set<string>;
        }
      >
    );

    // Transform and combine the data
    const result = organizations.map((org) => {
      const scores = orgScores[org.id]; // We know this exists because we filtered organizations

      const parentTitle = (org.parent as unknown as { title: string })?.title || '';

      return {
        organization_id: org.id,
        title: org.title,
        parent_organization_title: parentTitle,
        score: Math.round(scores.total_weighted_condition_score / scores.total_building_size) || null,
        confidence: Math.round(scores.total_weighted_confidence_score / scores.total_building_size) || null,
        address: Array.from(scores.addresses)
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
        postal_code: Array.from(scores.postal_codes)
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
        bfe_numbers: Array.from(scores.bfe_numbers)
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
        energy_labels: Array.from(scores.energy_labels)
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
        task_count: org.task?.length || 0,
        task_status: org.task?.map((t) => t.isDone).join(', ') || '',
      };
    });

    // Sort the results
    const sortField = orderBy as keyof (typeof result)[0];
    const sortedResult = result.sort((a, b) => {
      const aVal = a[sortField];
      const bVal = b[sortField];

      // Handle null and undefined values
      if (aVal === null || aVal === undefined) return ascending ? -1 : 1;
      if (bVal === null || bVal === undefined) return ascending ? 1 : -1;

      // For title field, use specialized string comparison
      if (sortField === 'title' || sortField === 'parent_organization_title') {
        return ascending
          ? String(aVal).localeCompare(String(bVal), undefined, { numeric: true, sensitivity: 'base' })
          : String(bVal).localeCompare(String(aVal), undefined, { numeric: true, sensitivity: 'base' });
      }

      // Handle different types appropriately
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return ascending
          ? aVal.toLowerCase().localeCompare(bVal.toLowerCase())
          : bVal.toLowerCase().localeCompare(aVal.toLowerCase());
      }

      if (typeof aVal === 'number' && typeof bVal === 'number') {
        return ascending ? aVal - bVal : bVal - aVal;
      }

      // For boolean values
      if (typeof aVal === 'boolean' && typeof bVal === 'boolean') {
        return ascending ? (aVal === bVal ? 0 : aVal ? 1 : -1) : aVal === bVal ? 0 : aVal ? -1 : 1;
      }

      // If types don't match or unsupported type, convert to strings
      return ascending ? String(aVal).localeCompare(String(bVal)) : String(bVal).localeCompare(String(aVal));
    });

    // Apply pagination
    const paginatedResult = sortedResult.slice(start, start + pageSize);

    return {
      data: paginatedResult,
      error: null,
      count: count || 0,
    };
  } catch (error) {
    return {
      data: null,
      error,
      count: 0,
    };
  }
};
